
import { defineComponent } from "vue";
import {
  Button,
  Table,
  Modal,
  Input,
  Select,
  message,
  Checkbox,
  Popover,
} from "ant-design-vue";
import {
  PlusCircleOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  FileAddFilled,
  ReloadOutlined,
  ColumnHeightOutlined,
  EditOutlined,
} from "@ant-design/icons-vue";
import {
  singleProductParametersData,
  parametersColumns,
} from "@/api/fixedData.js";
import {
  customParameters,
  addCustomParameters,
  editCustomParameters,
} from "@/api/terminalList.js";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const { Option } = Select;
let { Group: CheckboxGroup } = Checkbox;
export default defineComponent({
  components: {
    Button,
    Table,
    Modal,
    Input,
    Select,
    Option,
    PlusCircleOutlined,
    UnorderedListOutlined,
    DeleteOutlined,
    aCheckbox: Checkbox,
    aCheckboxGroup: CheckboxGroup,
    Popover,
    FileAddFilled,
    ReloadOutlined,
    ColumnHeightOutlined,
    EditOutlined,
    DecorateModalTitle,
  },
  data() {
    return {
      data: singleProductParametersData,
      columns: parametersColumns,
      //模态框是否可见
      visible: false,
      //模态框标题
      modalTitle: "新增参数",
      //新增参数的四个值
      extraName: "",
      extraType: "text",
      extraValueOut: "", //仅是输入框的值  只有要通过 selectValue传
      extraTips: "",
      //选项内容的数组
      selectValue: [],
      scrollY: 500,
      //修改的拓展字段
      extraList: [],
      typeDisabled: true,
      editId: 0,
      loading: false,
      //table的样式
      batchStyle: "middle",
      confirmLoading: false,
      //点击编辑时候所有按钮禁用 再次点击里面的编辑的时候可以修改
      preDisable: false,
    };
  },
  methods: {
    handelEdit(record) {
      this.preDisable = true;
      this.typeDisabled = true;
      this.visible = true;
      this.modalTitle = "编辑参数";
      this.extraName = record.extraName;
      this.extraType = record.extraType;
      //单选多选待修改
      // this.extraValueOut: record.??????
      this.extraTips = record.extraTips;
      this.selectValue = record.selectValue.split(";");
      this.editId = record.id;
    },
    showModal() {
      if (this.extraList.length >= 20) {
        message.warning("新增参数最大数量为20,当前已达最大数量无法继续新增");
        return;
      }
      this.preDisable = false;
      this.extraName = "";
      this.extraType = "text";
      this.extraTips = "";
      this.selectValue = [];
      this.typeDisabled = false;
      this.editId = 0;
      this.visible = true;
    },
    //点击模态框确定按钮
    handleOk(record) {
      if (this.preDisable) {
        this.preDisable = false;
        return;
      }

      let extraName = (this as any).$refs.extraName.value.trim();
      let extraList = this.extraList.map((item: any) => {
        return item.extraName;
      });
      let filterArray = extraList.filter((item) => {
        return item === this.extraName;
      });
      if (
        (extraList.includes(extraName) && !this.editId) ||
        (this.editId && filterArray.length >= 1)
      ) {
        message.warning(`该参数已存在不可重复使用`);
        return;
      }

      let flag1 = (this as any).checkExtraValueOut(
        (this as any).$refs.extraName,
        0,
        10,
        "参数名称"
      );
      if (!flag1) {
        return;
      }

      if (this.extraType !== "radio" && this.extraType !== "checkbox") {
        let flag2 = (this as any).checkExtraValueOut(
          (this as any).$refs.extraTips,
          0,
          15,
          "提示信息"
        );
        if (!flag2) {
          return;
        }
      }
      if (this.extraType === "radio" && this.selectValue.length === 0) {
        (this as any).$refs.extraValueOut.focus();
        message.warning(`选项内容不能为空`);
        return;
      }
      if (
        (this.extraType === "radio" || this.extraType === "checkbox") &&
        this.selectValue.length < 2
      ) {
        (this as any).$refs.extraValueOut.focus();
        message.warning(`选项内容不能少于两条`);
        return;
      }
      if (
        (this.extraType === "radio" || this.extraType === "checkbox") &&
        this.selectValue.length > 5
      ) {
        (this as any).$refs.extraValueOut.focus();
        message.warning(`选项内容不能超过五条`);
        return;
      }
      //拼接单选多选
      var selectValueStr = "";
      for (var i = 0; i < this.selectValue.length; i++) {
        if (i != this.selectValue.length - 1) {
          selectValueStr = selectValueStr + this.selectValue[i] + ";";
        } else {
          selectValueStr += this.selectValue[i];
        }
      }
      this.confirmLoading = true;
      if (this.editId) {
        editCustomParameters({
          id: this.editId,
          extraName: this.extraName.trim(),
          selectValue: selectValueStr,
          extraTips: this.extraTips,
          isUnique: record.isUnique,
          isEmpty: record.isEmpty,
          isEdit: record.isEdit,
        })
          .then((res) => {
            if (res.data.success) {
              message.success("编辑成功");
              this.data = singleProductParametersData;
              this.getCustomParameters().then(() => {
                this.visible = false;
                this.confirmLoading = false;
                this.extraName = "";
                this.extraType = "text";
                this.extraTips = "";
                this.selectValue = [];
              });
            } else {
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      } else {
        //  单选项多选项怎么传尚未定好  ----------------------------------------------------
        addCustomParameters({
          funCode: "singleproduct",
          extraName: this.extraName.trim(),
          // extraCode: "district-6",
          selectValue: selectValueStr,
          extraType: this.extraType,
          extraTips: this.extraTips,
          isUnique: 1,
          isEmpty: 1,
          isEdit: 1,
        })
          .then((res) => {
            if (res.data.success) {
              message.success("新增成功");
              this.data = singleProductParametersData;
              this.getCustomParameters().then(() => {
                this.visible = false;
                this.confirmLoading = false;
                this.extraName = "";
                this.extraType = "";
                this.extraTips = "";
                this.selectValue = [];
              });
            } else {
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      }
    },
    //添加和删除选项
    addOptionContent() {
      let flag = (this as any).checkExtraValueOut(
        (this as any).$refs.extraValueOut,
        0,
        10,
        "选项内容"
      );
      for (var i = 0; i < this.selectValue.length; i++) {
        if (this.extraValueOut === this.selectValue[i]) {
          (this as any).$refs.extraValueOut.focus();
          message.warning(`选项内容不能重复`);
          return;
        }
      }
      if (flag) {
        (this as any).selectValue = [...this.selectValue, this.extraValueOut];
        this.extraValueOut = "";
      }
    },
    delOption(index) {
      this.selectValue.splice(index, 1);
    },
    //获取拓展字段
    getCustomParameters() {
      return customParameters("singleproduct").then((res: any) => {
        this.extraList = res.data.data;
        this.data = [...singleProductParametersData, ...this.extraList];
      });
    },
    computedType(type) {
      switch (type) {
        case "text":
          return "文本";
        case "number":
          return "数字";
        case "datetime":
          return "日期时间";
        case "radio":
          return "单选项";
        case "checkbox":
          return "多选项";
        default:
          return "错误";
      }
    },
    //检验字数
    checkExtraValueOut(item, min, max, name) {
      let length = item && item.value.trim().length;
      if (length <= min) {
        item.focus();
        if (min == 0) {
          message.warning(`${name}为必填项`);
        } else {
          message.warning(`${name}字数最少为${min}字`);
        }
      } else if (length > max) {
        item.focus();
        message.warning(`${name}字数最多为${max}字`);
      } else {
        return true;
      }
    },
    changeChecked(record, changeType) {
      (this as any).extraList = this.extraList.map((item: any) => {
        if (item.id === record.id) {
          if (item[changeType] === 0) {
            item[changeType] = 1;
          } else {
            item[changeType] = 0;
          }
        }
        return item;
      });
      //
    },
    goback() {
      history.go(-1);
    },
    submit() {
      let subList = [...this.extraList];
      let Plist: any = [];
      for (let i: any = 0; i < subList.length; i++) {
        delete subList[i]["count"];
        Plist.push(editCustomParameters(subList[i]));
      }
      Promise.all([...Plist]).then((res: any) => {
        let flag = true;
        for (let i: any = 0; i < res.length; i++) {
          if (!res[i].data.success) {
            flag = false;
            // message.error(i.data.data);
          }
        }
        if (flag) {
          message.success("更新成功");
          this.getCustomParameters();
        }
      });
    },
    search() {
      location.reload();
    },
  },
  mounted() {
    this.scrollY = document.body.clientHeight - 369;
    this.getCustomParameters();
  },
});
